import React from "react";
import Card from "~/components/styled/card";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "~/components/layout";
import { ArrowRightIcon } from "@heroicons/react/outline";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const PrivatePage = () => {
	return (
		<Layout>
			<GatsbySeo
				title="Private Bike Tours and Cycling Holidays"
				description="Enter bespoke, your very own custom private bicycle tour in India and Asia: Vietnam, Cambodia, Laos, Thailand, Sri Lanka, Bhutan."
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-US",
				// 		href: "https://www.artofbicycletrips.com/private",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com/private",
				// 	},
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in/private",
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: "https://www.artofbicycletrips.com/private",
				// 	},
				// ]}
			/>
			<section className="my-20">
				<h1 className=" text-center">
					<span className="text-primary">Design a Private Bike Tour</span>
				</h1>
				<h2 className="text-center mb-20">or select a standard itinerary</h2>

				<p className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl leading-loose font-light mb-20 mx-auto">
					Some trips are for special occasions. Celebrating a birthday with a group
					of your closest friends? Cherishing an anniversary of being together with
					your special someone? Planning some quality time together with your family?
					Looking to let your hair down with your team after a strenuous quarter at
					work? Whatever be the occasion, our private trips provide the same amazing
					itineraries and the same quality service that we’re known for, but this
					time with the companions of your choice. Just select any tour from our
					website and let us know you want it to be private. Alternatively, you can
					create a custom itinerary for your private trips in the following two ways:
				</p>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-20">
					<Card>
						<div className="">
							<StaticImage
								className="rounded-t-lg shadow-lg transform"
								src="../images/thailand-bike-tour-53.jpeg"
								alt="A dinosaur"
								layout="FULL_WIDTH"
								aspectRatio="1.8"
								loading="lazy"
							/>
						</div>
						<h3 className="px-4 py-4">
							100% tailor-made based on your travel interests
						</h3>
						<p className="px-4 pb-4 prose prose-sm">
							Ideal for family and friends, local clubs, and corporates. Our team works
							closely with you and designs a trip from the ground up based on your
							dates, budget, destination, and travel interests.
						</p>
						<p className="px-4 pb-4 prose prose-sm">
							Pedal the Vietnamese coastline, kayak the backwaters of Kerala, or go
							hiking in Sri Lanka. There is no better way to travel the world!
						</p>

						<div className="my-10 text-center">
							{/* <a
								id="send_message"
								href="mailto:contact@artofbicycletrips.com"
								className="px-8 py-3 border border-transparent font-medium rounded-full text-white bg-primary hover:bg-primary md:py-4 md:px-10"
							>
								Design trip
								<ArrowRightIcon className="ml-2 h-4 w-4 text-white inline-flex"></ArrowRightIcon>
							</a> */}

							<Link
								to="/design-trip?utm_source=private&utm_medium=private-section&utm_campaign=design-trip"
								className="px-8 py-3 border border-transparent font-medium rounded-full text-white bg-primary hover:bg-primary md:py-4 md:px-10"
							>
								Design trip
								<ArrowRightIcon className="ml-2 h-4 w-4 text-white inline-flex"></ArrowRightIcon>
							</Link>

							{/* <PopupButton
								id="Z8WEZQ"
								className="px-8 py-3 border border-transparent font-medium rounded-full text-white bg-primary hover:bg-primary md:py-4 md:px-10"
							>
								Design trip
								<ArrowRightIcon className="ml-2 h-4 w-4 text-white inline-flex"></ArrowRightIcon>
							</PopupButton> */}
						</div>
					</Card>
					<Card>
						<div className="">
							<StaticImage
								className="rounded-t-lg shadow-lg transform"
								src="../images/south-india-bike-tour-3.jpeg"
								alt="A dinosaur"
								layout="FULL_WIDTH"
								aspectRatio="1.8"
								loading="lazy"
							/>
						</div>
						<h3 className="px-4 py-4">
							Select a standard itinerary or customize further
						</h3>
						<p className="px-4 pb-4 prose prose-sm">
							We offer private departures on almost all our itineraries, and for groups
							of four or more, they cost the same as our standard trips!
						</p>
						<p className="px-4 pb-4 prose prose-sm">
							If you don’t find a suitable itinerary then you can always customize it
							further to incorporate any special requests. Our team is equipped to work
							with your needs and create that perfect active experience for you.
						</p>
						<div className="my-10 text-center">
							<Link
								to="/tours?utm_source=private&utm_medium=explore-section&utm_campaign=explore-trip"
								className="px-8 py-3 border border-transparent font-medium rounded-full text-white bg-primary hover:bg-primary md:py-4 md:px-10"
							>
								Explore trips
								<ArrowRightIcon className="ml-2 h-4 w-4 text-white inline-flex"></ArrowRightIcon>
							</Link>
						</div>
					</Card>
				</div>
			</section>
		</Layout>
	);
};

export default PrivatePage;
